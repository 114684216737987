import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Button, 
  TextField, 
  Typography, 
  Box,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Snackbar
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import api from '../api';

const ResetPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();
  const { token } = useParams(); // Get token from URL if present

  const steps = ['Enter Phone Number', 'Set New Password'];

  useEffect(() => {
    if (token) {
      setActiveStep(1); // Skip to password reset if token is present
    }
  }, [token]);

  const handleSendResetLink = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/forgot-password', { phone: phoneNumber });
      setSnackbar({
        open: true,
        message: 'Reset link sent to your email',
        severity: 'success'
      });
      // Don't advance step - user needs to click link in email
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'Failed to send reset link',
        severity: 'error'
      });
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setSnackbar({
        open: true,
        message: "Passwords don't match",
        severity: 'error'
      });
      return;
    }

    try {
      await api.post('/reset-password', {
        token,
        new_password: newPassword
      });
      
      setSnackbar({
        open: true,
        message: 'Password reset successfully',
        severity: 'success'
      });
      
      // Redirect to login after successful reset
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'Failed to reset password',
        severity: 'error'
      });
    }
  };

  return (
    <Box sx={{
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      
      <Stepper activeStep={activeStep} sx={{ width: '100%', mt: 3 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      
      <Box component="form" 
        onSubmit={activeStep === 0 ? handleSendResetLink : handleResetPassword} 
        noValidate 
        sx={{ mt: 3, width: '100%', maxWidth: 400 }}
      >
        {activeStep === 0 ? (
          <TextField
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            autoComplete="tel"
            autoFocus
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        ) : (
          <>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </>
        )}
        
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {activeStep === 0 ? 'Send Reset Link' : 'Reset Password'}
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ResetPassword;